import React from 'react';
import { Card } from 'react-bootstrap';

import UserForm from './UserForm';



export default function RegistrationForm (props) {
  return (
    <div className="user-form">
      <header>
        <h1>Edit your profile</h1>
      </header>
      <Card className='mt-5'>
        <Card.Body>
          <UserForm />
        </Card.Body>
      </Card>
    </div>
  );
}
