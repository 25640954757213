import React, { useEffect } from 'react';
import { Table } from 'react-bootstrap';

import quotes from '../data/quotes';


export default () => {

  return (
    <Table striped bordered size='sm'>
      <thead>
        <tr>
          <th>Quote</th>
          <th>Attribution</th>
        </tr>
      </thead>
      <tbody>
        {quotes.map(({quote, attr}, i) => (
          <tr key={i}>
            <td>{quote}</td>
            <td>{attr}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
