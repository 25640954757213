import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ProgressBar, Carousel, Alert } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import moment from 'moment';
import styled, { css } from 'styled-components';


import quotes from '../data/quotes';
import avatars from '../data/avatars';
import mountains from '../data/mountains';



const breakpoint = 1000;


const QuoteWrapper = styled.div`
  position: relative;
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0;
  padding: 1.5rem 2rem 0;
  p {
    margin: 0;
    + p {
      margin-top: 1em;
    }
  }
  .left, .right {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    font-size: 2.5rem;
    cursor: pointer;
  }
  .left {
    left: 0.25rem;
  }
  .right {
    right: 0.25rem;
  }
  @media (min-width: ${breakpoint}px) {
    font-size: 1.5rem;
    padding: 1.5rem 3rem 0;
    .left, .right {
      font-size: 2.5rem;
    }
  }
`;


const Banner = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 300px;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;


const Attaboy = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  position: relative;
  .progress {
    margin-top: 2em;
  }
  .progress-label {
    margin: 0.5em 0 0;
  }
`;


const Mountain = styled.div`
  position: relative;
  margin-left: 5%;
  > img {
    width: 100%;
    height: auto;
  }
`;


const Avatar = styled.div`
  position: absolute;
  width: 30%;
  margin-left: -15%;
  transition: left 0.5s, bottom 0.5s;
  @media (min-width: ${breakpoint}px) {
    width: 20%;
    margin-left: -10%;
  }
  img {
    width: 100%;
    height: auto;
    margin-top: 0.5rem;
  }
  div {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 10em;
    margin-left: -5em;
    margin-top: 0.25em;
    white-space: nowrap;
  }
`;


const UnlockedAvatars = styled.div`
  font-weight: bold;
  text-align: left;
  margin-top: 1rem;
  p {
    margin: 0;
  }
  img {
    cursor: pointer;
    max-width: 100%;
    height: auto;
  }
  .avatars {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  }
  .avatar {
    max-width: 16%;
    padding-top: 0.5rem;
  }
`;


const Progress = styled.div`
  margin-top: 2em;
  .progress-label {
    margin: 0.5em 0 0;
  }
`;



export default ({maxMiles}) => {

  // get user's gender and purchases
  const { gender, purchases } = useSelector((store) => store.authedUser);

  // get all the user's runs and calculate the total
  const runs = useSelector((store) => store.runs.runs.sort((a, b) => new Date(a.date) - new Date(b.date)));
  const total = calculateTotal(runs);

  // they only get credit for miles run up to the max they've registered for, so figure out which runs those are
  const countedMiles = total.mi > maxMiles ? maxMiles : total.mi;
  let countedRuns = runs;
  if (total.mi > countedMiles) {
    const indexOfRunThatPassesMax = runs.findIndex((run, i) => {
      return calculateTotal(runs.slice(0, i + 1)).mi >= maxMiles;
    });
    countedRuns = runs.slice(0, indexOfRunThatPassesMax + 1);
  }

  // let them pick which run is active, for purposes of displaying a quote
  const [ activeRunIndex, setActiveRunIndex ] = useState(countedRuns.length - 1);
  // the total run up through the currently active run
  const activeTotal = calculateTotal(countedRuns.slice(0, activeRunIndex + 1));
  // the total run before the currently active run
  const previousTotal = calculateTotal(countedRuns.slice(0, activeRunIndex));

  // position of the avatar on the mountain
  let milesForPositionCalculation = activeTotal.mi;
  // can't be greater than the max miles allowed by their purchases
  if (milesForPositionCalculation > maxMiles) milesForPositionCalculation = maxMiles;
  // resest the position every 100 miles
  milesForPositionCalculation = milesForPositionCalculation % 100;
  // convert to css position
  let left = milesForPositionCalculation / 100;
  let bottom = left;
  // format for placement on mountain
  left = left * 88 + '%';
  bottom = bottom * 60 + '%';

  // figure out which avatar to display
  // first get the avatar just after the one we should show the user
  let maxAvatarIndex = avatars.findIndex((avatar) => avatar.at > countedMiles);
  // if it's -1 that means the user should be on the last available avatar
  if (maxAvatarIndex === -1) {
    maxAvatarIndex = avatars.length - 1;
  // otherwise, minus 1 so the user will be on the last avatar they've reached (instead of the one just after it)
  } else if (maxAvatarIndex > 0) {
    maxAvatarIndex--;
  }

  // let them pick which avatar to show on the mountain
  const [ activeAvatarIndex, setActiveAvatarIndex ] = useState(maxAvatarIndex);

  // show a quote based on the active run, but if the user has more
  // runs than there are available quotes start looping the quotes
  const quoteIndex = activeRunIndex % quotes.length;

  // count how many days they've been running
  const dayCount = useSelector((store) => {
    const runs = store.runs.runs.sort((a, b) => new Date(a.date) - new Date(b.date));
    if (runs.length === 0) return 0;
    const start = moment(runs[0].date).startOf('day');
    const end = total.mi >= 200 ? moment(runs.slice(-1)[0].date) : moment();
    return Math.ceil(end.diff(start, 'days', true));
  });

  // if the active run is the one that passes a 100x mile threshold we should show a mountain banner
  // which mountain we show depends on what order the user purchased the mountains
  let bannerToShow = false;
  purchases.forEach((purchase, i) => {
    const milestone = (i + 1) * 100;
    if (activeTotal.mi >= milestone && previousTotal.mi < milestone) bannerToShow = purchase;
  });

  // when a run is added reset the quote and avatar to the max
  useEffect(() => {
    setActiveRunIndex(countedRuns.length - 1);
    setActiveAvatarIndex(maxAvatarIndex);
  }, [runs]);

  return (
    <Attaboy>
      {bannerToShow
        ? <Banner>
            <img src={`/mountains/${mountains[bannerToShow].image}`} />
          </Banner>
        : <Mountain>
            <Avatar style={{left, bottom}}>
              <img src={`/runners/${gender}/${avatars[activeAvatarIndex].image}`} width='100' height='100' />
              <div>
                {avatars[activeAvatarIndex].title}
              </div>
            </Avatar>
            <img src='/mountain-c.svg' width='1000' height='499'/>
          </Mountain>
      }
      {countedRuns.length &&
        <QuoteWrapper>
          <blockquote>
            <p>&ldquo;{quotes[quoteIndex].quote}&rdquo;</p>
            {quotes[quoteIndex].attr &&
              <p>– {quotes[quoteIndex].attr}</p>
            }
          </blockquote>
          {activeRunIndex !== 0 &&
            <ChevronLeft
              className='left'
              onClick={() => setActiveRunIndex(activeRunIndex-1)}
            />
          }
          {activeRunIndex !== (countedRuns.length - 1) &&
            <ChevronRight
              className='right'
              onClick={() => setActiveRunIndex(activeRunIndex+1)}
            />
          }
        </QuoteWrapper>
      }
      <Progress>
        <ProgressBar
          now={Math.round(total.mi / maxMiles * 100)}
          variant='success'
        />
        {total.mi >= maxMiles
          ? <p className='progress-label'>You ran <strong>{total.mi} miles</strong> ({total.km} km) in <strong>{dayCount}</strong> days.</p>
          : <p className='progress-label'>You've run <strong>{total.mi} miles</strong> ({total.km} km) in <strong>{dayCount}</strong> days.</p>
        }
      </Progress>
      {maxAvatarIndex > 0 &&
        <UnlockedAvatars>
          <p>Unlocked Avatars:</p>
          <div className='avatars'>
            {avatars.slice(0, maxAvatarIndex + 1).map((avatar, i) => (
              <div className='avatar' key={i}>
                <img
                  src={`/runners/${gender}/${avatars[i].image}`}
                  width='75'
                  height='75'
                  onClick={() => setActiveAvatarIndex(i)}
                />
              </div>
            ))}
          </div>
        </UnlockedAvatars>
      }
    </Attaboy>

  );

}



const calculateTotal = (runs) => {
  const total = runs.reduce((soFar, run) => {
    if (!run.distance) return soFar;
    if (run.unit === 'km') return soFar + run.distance * 0.62137119;
    return soFar + run.distance;
  }, 0);
  // https://stackoverflow.com/a/11832950
  return {
    mi: Math.round((total + Number.EPSILON) * 100) / 100,
    km: Math.round((total * 1.609344 + Number.EPSILON) * 100) / 100,
  };
};
