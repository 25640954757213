export default [
  {
    at: 0,
    title: 'Beginner',
    image: '0.svg',
  },
  {
    at: 10,
    title: 'Trail Runner',
    image: '1.svg',
  },
  {
    at: 20,
    title: 'Hiker',
    image: '2.svg',
  },
  {
    at: 30,
    title: 'Lumber Jack',
    image: '3.svg',
  },
  {
    at: 40,
    title: 'Dancer',
    image: '4.png',
  },
  {
    at: 50,
    title: 'Warrior',
    image: '5.svg',
  },
  {
    at: 60,
    title: 'Knight',
    image: '6.png',
  },
  {
    at: 70,
    title: 'Viking',
    image: '7.png',
  },
  {
    at: 80,
    title: 'Monk',
    image: '8.png',
  },
  {
    at: 90,
    title: 'Ninja',
    image: '9.svg',
  },
  {
    at: 110,
    title: 'Robot',
    image: '11.svg',
  },
  {
    at: 120,
    title: 'Big Foot',
    image: '12.svg',
  },
  {
    at: 130,
    title: 'Mountaineer',
    image: '13.png',
  },
  {
    at: 140,
    title: 'Animal Lover',
    image: '14.svg',
  },
  {
    at: 150,
    title: 'Sleepwalker',
    image: '15.svg',
  },
  {
    at: 160,
    title: 'Brain Eater',
    image: '16.png',
  },
  {
    at: 170,
    title: 'Stick Person',
    image: '17.svg',
  },
  {
    at: 180,
    title: 'Extra Terrestrial',
    image: '18.svg',
  },
  {
    at: 190,
    title: 'Deer',
    image: '19-b.svg',
  },
  {
    at: 210,
    title: 'Tourist',
    image: '21.svg',
  },
  {
    at: 220,
    title: 'Reaper',
    image: '22.svg',
  },
  {
    at: 230,
    title: 'Skeleton',
    image: '23.svg',
  },
  {
    at: 240,
    title: 'Genie',
    image: '24.png',
  },
  {
    at: 250,
    title: 'Zebra',
    image: '25.png',
  },
  {
    at: 260,
    title: 'Super Food',
    image: '26.svg',
  },
  {
    at: 270,
    title: 'Super Food Sidekick',
    image: '27.svg',
  },
  {
    at: 280,
    title: 'Weekend Warrior',
    image: '28.svg',
  },
  {
    at: 290,
    title: 'Rocker',
    image: '29.svg',
  },
  {
    at: 310,
    title: 'Polar Bear',
    image: '31.svg',
  },
  {
    at: 320,
    title: 'Late to Work',
    image: '32.svg',
  },
  {
    at: 330,
    title: 'Eskimo',
    image: '33.svg',
  },
  {
    at: 340,
    title: 'Too Lazy to Walk',
    image: '34.svg',
  },
  {
    at: 350,
    title: 'Attack Mode',
    image: '35.svg',
  },
  {
    at: 360,
    title: 'T-Rex',
    image: '36.png',
  },
  {
    at: 370,
    title: 'Show Off',
    image: '37.svg',
  },
  {
    at: 380,
    title: 'Golfer',
    image: '38.svg',
  },
  {
    at: 390,
    title: 'Santa Claus',
    image: '39.png',
  },
];
