import {
  ADD_RUN,
  EDIT_RUN,
  DELETE_RUN,
  SET_RUNS,
} from '../actions/runs';



export default function runs (state = {runs: []}, action) {
  let runs;
  switch (action.type) {
    case SET_RUNS:
      return {
        runs: action.runs,
      };
    case ADD_RUN:
      runs = state.runs.concat([action.run]);
      return {
        runs,
      }
    case EDIT_RUN:
      runs = state.runs.filter((run) => run._id !== action.run._id).concat([action.run]);
      return {
        runs,
      }
    case DELETE_RUN:
      runs = state.runs.filter((run) => run._id !== action._id);
      return {
        runs,
      }
    default:
      return state;
  }
}
