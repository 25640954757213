import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import UserForm from './UserForm';



export default ({ children }) => {

  const { gender } = useSelector((store) => store.authedUser);

  if (gender) return children;

  return (
    <div className="user-form">
      <header>
        <h1>Pick your avatar</h1>
      </header>
      <Card className='mt-5'>
        <Card.Body>
          <UserForm genderOnly />
        </Card.Body>
      </Card>
    </div>
  );
}
