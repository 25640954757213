import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { handleLogoutAuthedUser } from '../actions/authedUser';



const LogoutLink = () => {

  const dispatch = useDispatch();

  const handleLogout = (e) => {
    dispatch(handleLogoutAuthedUser());
  }

  return (
    <Nav.Link onClick={handleLogout}>Log out</Nav.Link>
  );

}



export default () => {

  const user = useSelector((store) => store.authedUser);

  return (
    <Navbar bg='dark' variant='dark'>
      <Navbar.Brand href={user ? '/profile' : '/'} className='mr-auto'>Runner's High Challenge</Navbar.Brand>
      {user &&
        <Nav activeKey={location.pathname}>
          {user.isAdmin &&
            <NavDropdown title='Admin'>
              <NavDropdown.Item href='/admin/users'>Users</NavDropdown.Item>
              <NavDropdown.Item href='/admin/quotes'>Quotes</NavDropdown.Item>
            </NavDropdown>
          }
          <Nav.Link href='/profile/edit'>
            <span className='d-none d-md-inline'>Edit </span>Profile
          </Nav.Link>
          <LogoutLink />
        </Nav>
      }
    </Navbar>
  );

}
