import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import LoadingBar, { showLoading, hideLoading } from 'react-redux-loading-bar';

import { handleInitialData } from '../actions/shared';
import Nav from './Nav';
import { IfAuthed, IfNotAuthed } from './Authed';
import IfAdmin from './IfAdmin';
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import Profile from './Profile';
import UsersList from './UsersList';
import UserLog from './UserLog';
import TestLogging from './TestLogging';
import EditProfile from './EditProfile';
import GenderFormProvider from './GenderFormProvider';
import QuotesList from './QuotesList';



export default function App () {

  const dispatch = useDispatch();

  // Get user state
  // null: unknown user state (loading)
  // false: user not logged in (show login forms)
  // object: user logged in
  const user = useSelector((store) => store.authedUser);
  const loading = useSelector((store) => store.loading);

  useEffect(() => {
    dispatch(handleInitialData());
  }, [dispatch]);

  return (
    <Router>
      <Nav />
      <LoadingBar className='loading-bar' />
      <Container className='page'>
        {loading === true ? null :
          <Switch>
            <Route path='/' exact>
              <IfNotAuthed>
                <LoginForm />
              </IfNotAuthed>
            </Route>
            <Route path='/register' exact>
              <IfNotAuthed>
                <RegistrationForm />
              </IfNotAuthed>
            </Route>
            <Route path='/activate/:code' exact>
              <IfNotAuthed>
                <RegistrationForm />
              </IfNotAuthed>
            </Route>
            <Route path='/profile' exact>
              <IfAuthed>
                <GenderFormProvider>
                  <Profile />
                </GenderFormProvider>
              </IfAuthed>
            </Route>
            <Route path='/profile/edit' exact>
              <IfAuthed>
                <EditProfile />
              </IfAuthed>
            </Route>
            <Route path='/admin/users' exact>
              <IfAdmin>
                <UsersList />
              </IfAdmin>
            </Route>
            <Route path='/admin/users/:id' exact>
              <IfAdmin>
                <UserLog />
              </IfAdmin>
            </Route>
            <Route path='/admin/quotes' exact>
              <QuotesList />
            </Route>
            <Route path='/test-logging' exact>
              <TestLogging />
            </Route>
            <Route path='*'>
              <h1>404: Page not found</h1>
            </Route>
          </Switch>
        }
      </Container>
      <div id='footer'>
        &copy; <a href='https://www.marathontrainingacademy.com' target='_blank'>Marathon Training Academy</a>
        &nbsp;&bull;&nbsp; <a href='mailto:trevor@marathontrainingacademy.com'>Support</a>
      </div>
    </Router>
  );
}
