import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, Form, Button } from 'react-bootstrap';
import { isEmail } from 'validator';
import { useDispatch } from 'react-redux';

import { loginUser } from '../api/user';
import { handleInitialData } from '../actions/shared';
import logger from '../lib/logger';



export default function LoginForm () {

  const dispatch = useDispatch();

  // get the state provided if we get redirected here from the registration form
  const {state} = useLocation();

  const [email, setEmail] = useState((state && state.email) || '');
  const [emailError, setEmailError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !isEmail(email)) {
      return setEmailError('invalid');
    };
    loginUser(email)
      .then((response) => {
        switch (response.status) {
          case 'success':
            return dispatch(handleInitialData());;
          case 'notFound':
            return setEmailError('notFound');
          case 'validationError':
            return setEmailError('invalid');
          default:
            console.log(response);
            logger.push({error: 'error logging in', email, response});
            return alert('An error occured');
        }
      })
      .catch((err) => {
        console.log(err);
        logger.push({error: 'error logging in', err});
        alert('An error occured');
      })
    ;
  }

  return (
    <div className="user-form login-form">
      <header>
        <h1>Login</h1>
        <p>Enter your email address to access the run tracker.</p>
      </header>
      <Card>
        <Card.Body>
          <Form
            onSubmit={handleSubmit}
            noValidate
          >
            <Form.Group controlId='email'>
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type='email'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={emailError}
              />
              {emailError === 'invalid' &&
                <Form.Control.Feedback type='invalid'>
                  Please enter a valid email address.
                </Form.Control.Feedback>
              }
              {emailError === 'notFound' &&
                <Form.Control.Feedback type='invalid'>
                  No account was found with this email address.
                </Form.Control.Feedback>
              }
            </Form.Group>
            <Button type='submit' block>Submit</Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
