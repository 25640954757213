export default {
  'matterhorn': {
    image: 'matterhorn.jpg',
  },
  'fuji': {
    image: 'fuji.jpg',
  },
  'kilimanjaro': {
    image: 'kilimanjaro.jpg',
  },
  'denali': {
    image: 'denali.jpg',
  },
}
