import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card } from 'react-bootstrap';

import RunForm from './RunForm';
import RunsTable from './RunsTable';
import Attaboy from './Attaboy';



export default function Profile () {

  const name = useSelector((store) => store.authedUser && store.authedUser.firstName);
  const hasRuns = useSelector((store) => !!store.runs.runs.length);
  const maxMiles = useSelector((store) => store.authedUser?.purchases.length * 100);

  if (!maxMiles) {
    return (
      <h3 style={{textAlign: 'center'}}>Your must purchase a run to access the Run Tracker</h3>
    );
  }

  return (
    <Container className='profile'>
      <Attaboy maxMiles={maxMiles} />
      <hr />
      <Row>
        <Col lg={6}>
          <h2>Run Tracker</h2>
          {hasRuns
            ? <RunsTable />
            : <Card>
                <Card.Header style={{
                  textAlign: 'center'
                }}>
                  <h4>Add your first run</h4>
                </Card.Header>
                <Card.Body>
                  <p>Track your progress by entering your runs below.</p>
                  <RunForm />
                </Card.Body>
              </Card>
          }
        </Col>
        <Col lg={6} className='mt-5 mt-lg-0' className='welcome-text'>

          <h2>{`Welcome${name ? ' ' + name : ''},`}</h2>
          <p>To track your progress enter your miles using the Run Tracker on the left and unlock the cool digital badges.  *Note: your medal will be shipped even if you don’t track your miles.</p>
          <p>Please make sure that you are medically cleared to run and always remember you have what it takes!</p>
          <hr />

          <h2>Listen to the Podcast</h2>
          <iframe
            id='embedPlayer'
            src='https://embed.podcasts.apple.com/us/podcast/marathon-training-academy/id359208879?itsct=podcast_box_player&amp;itscg=30200&amp;ls=1&amp;theme=auto'
            height='450px'
            sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation'
            allow='autoplay *; encrypted-media *; clipboard-write'
            style={{
              border: 'none',
              width: '100%',
              maxWidth: '660px',
              overflow: 'hidden',
              borderRadius: '10px',
              transform: 'translateZ(0px)',
              animation: '2s ease 0s 6 normal none running loading-indicator',
              backgroundColor: 'rgb(228, 228, 228)',
            }}
          ></iframe>

        </Col>
      </Row>
    </Container>
  );
}
