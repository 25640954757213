import React, { useState } from 'react';
import { useRouteMatch, useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import { isEmail } from 'validator';

import { registerUser, updateUser } from '../api/user';
import { setAuthedUser } from '../actions/authedUser';
import logger from '../lib/logger';



export default ({ genderOnly }) => {

  const user = useSelector((store) => store.authedUser);

  const match = useRouteMatch('/activate/:code');

  const code = match && match.params.code;

  const dispatch = useDispatch();
  const history = useHistory();

  // get the state provided when we get redirected here from the login form
  const {state} = useLocation();

  const [ email, setEmail ] = useState(user.email || (state && state.email) || '');
  const [ emailError, setEmailError ] = useState(false);
  const [ firstName, setFirstName ] = useState(user.firstName || '');
  const [ lastName, setLastName ] = useState(user.lastName || '');
  const [ gender, setGender ] = useState(user.gender || false);
  const [ genderError, setGenderError ] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setEmailError(false);
    if (!email || !isEmail(email)) {
      return setEmailError('invalid');
    }

    setGenderError(false);
    if (!gender) {
      return setGenderError(true);
    }

    const request = user ? updateUser : registerUser;

    request({email, firstName, lastName, code, gender})
      .then((response) => {
        switch (response.status) {
          case 'conflict':
            return setEmailError('alreadyUsed');
          case 'validationError':
            return setEmailError('invalid');
          case 'success':
            dispatch(setAuthedUser(response.user));
            return history.push('/profile');
          default:
            console.log(response);
            logger.push({error: 'error registering or updating', email, firstName, lastName, gender, response});
            return alert('An error occured');
        }
      })
      .catch((err) => {
        console.log(err);
        logger.push({error: 'error registering or updating', email, firstName, lastName, err});
        alert('An error occured');
      })
    ;
  }


  return (
    <Form
      onSubmit={handleSubmit}
      noValidate
    >
      {!genderOnly &&
        <>
          <Form.Group controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={emailError}
            />
            {emailError === 'invalid' &&
              <Form.Control.Feedback type='invalid'>
                Please enter a valid email address.
              </Form.Control.Feedback>
            }
            {emailError === 'alreadyUsed' &&
              <Form.Control.Feedback type='invalid'>
                There is already an account with this email address<br />
                <Link
                  to={{
                    pathname: '/',
                    state: {email},
                  }}
                >Click here to log in.</Link>
              </Form.Control.Feedback>
            }
          </Form.Group>
          <Form.Group controlId='firstName'>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type='text'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId='lastName'>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type='text'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Form.Group>
        </>
      }
      <div className={genderOnly && 'text-center'}>
        <label>Click on the runner icon you want to use.</label>
      </div>
      <Row>
        <Col>
          <Card
            className={'gender-button' + (gender === 'female' ? ' selected' : '')}
            onClick={() => setGender('female')}
          >
            <Card.Body>
              <img src='/runners/female/0.svg' width='100' height='100' />
              <br />Female
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card
            className={'gender-button' + (gender === 'male' ? ' selected' : '')}
            onClick={() => setGender('male')}
          >
            <Card.Body>
              <img src='/runners/male/0.svg' width='100' height='100' />
              <br />Male
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {genderError &&
        <p className='text-danger'>You must select an avatar</p>
      }
      <Button
        type='submit'
        block
        className='mt-3'
      >Submit</Button>
      {!genderOnly &&
        <div className='mt-3'>
          <small><strong>Tip:</strong> You don't need a password. Next time you'll be able to log in with just your email address.</small>
        </div>
      }
    </Form>
  );
}
