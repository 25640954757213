export default [
  {
    quote: 'We are what we repeatedly do. Excellence, then, is not an act, but a habit.',
    attr: 'Aristotle',
  },{
    quote: 'I don’t run to add days to my life, I run to add life to my days.',
    attr: 'Ronald Rook',
  },{
    quote: 'One run can change your day, many runs can change your life.',
  },{
    quote: 'Don’t be afraid to fail. It’s not the end of the world and in many ways it’s the first step toward learning something better and getting better at it.',
    attr: 'Jon Hamm',
  },{
    quote: 'Run when you can, walk if you have to, crawl if you must, just never give up.',
    attr: 'Dean Karnazes',
  },{
    quote: 'All great achievements require time.',
    attr: 'Maya Angelou',
  },{
    quote: 'If the sun comes up, I have a chance.',
    attr: 'Venus Williams',
  },{
    quote: 'Strength does not come from physical capacity. It comes from an indomitable will.',
    attr: 'Mahatma Gandhi',
  },{
    quote: 'Go fast enough to get there, but slow enough to see.',
    attr: 'Jimmy Buffett',
  },{
    quote: 'Just stick with it. What seems hard now will one day be your warm-up',
  },{
    quote: 'Pain is inevitable. Suffering is optional.',
    attr: 'Haruki Murakami',
  },{
    quote: 'Discipline equals freedom.',
    attr: 'Jocko Willink',
  },{
    quote: 'We must all suffer one of two things: discipline or the pain of regret.',
  },{
    quote: 'If you still look cute after working out, you didn’t go hard enough.',
  },{
    quote: 'Being active every day makes it easier to hear that inner voice.',
    attr: 'Haruki Murakami',
  },{
    quote: 'Success isn’t how far you got, but the distance you traveled from where you started.',
    attr: 'Steve Prefontaine.',
  },{
    quote: 'There is magic in misery. Just ask any runner.',
    attr: 'Dean Karnazes',
  },{
    quote: 'Good things come slow – especially in distance running.',
    attr: 'Bill Dellinger',
  },{
    quote: 'I’ve got 99 problems, so I went on a run to ignore them all.',
    attr: 'Unknown',
  },{
    quote: 'My feeling is that any day I’m too busy to run is a day that I am too busy.',
    attr: 'John Bryant',
  },{
    quote: 'Don’t dream of winning, train for it!',
    attr: 'Mo Farah',
  },{
    quote: 'Eventually you learn that the competition is against the little voice inside you that wants you to quit.',
    attr: 'George Sheehan',
  },{
    quote: 'Success breeds success!',
  },{
    quote: 'Forces in the universe are conspiring in your favor.',
  },{
    quote: 'Run like a bravey sleep like a baby dream like a crazy replace cant with maybe',
    attr: 'Alexi Pappas',
  },{
    quote: 'Most of our limitations are self-imposed.',
  },{
    quote: 'Never limit where running can take you.',
    attr: 'Bart Yasso',
  },{
    quote: 'I’ll be happy if running and I can grow old together.',
    attr: 'Haruki Murakami',
  },{
    quote: 'You are never too old to set another goal or to dream a new dream',
    attr: 'Malala Yousafzai',
  },{
    quote: 'What lies behind you and what lies in front of you, pales in comparison to what lies inside of you.',
    attr: 'Ralph Waldo Emerson',
  },{
    quote: 'Everything is figureoutable',
    attr: 'Marie Forleo',
  },{
    quote: 'Wake up determined, go to bed satisfied.',
    attr: 'Dwayne “The Rock” Johnson',
  },{
    quote: 'We are not our best intentions. We are what we do.',
    attr: 'Amy Dickinson',
  },{
    quote: 'What you get by achieving your goals is not as important as what you become by achieving your goals.',
    attr: 'Zig Ziglar',
  },{
    quote: 'You do not find the happy life. You make it.',
    attr: 'Camilla Eyring Kimball',
  },{
    quote: 'When things are going bad there’s going to be some good that comes from it.',
    attr: 'Jocko Willink',
  },{
    quote: 'If my mind can conceive it, if my heart can believe it, then I can achieve it.',
    attr: 'Muhammad Ali',
  },{
    quote: 'Embrace the glorious mess that you are.',
    attr: 'Elizabeth Gilbert',
  },{
    quote: 'Growth mindset > Fixed mindset',
  },{
    quote: 'Great works are performed, not by strength, but by perseverance.',
    attr: 'Samuel Johnson',
  },{
    quote: 'Anyone who has never made a mistake has never tried anything new.',
    attr: 'Albert Einstein',
  },{
    quote: 'Challenges are what make life interesting. Overcoming them is what makes life meaningful.',
    attr: 'Joshua Marine',
  },{
    quote: 'Life isn’t about finding yourself. Life is about creating yourself.',
    attr: 'George Bernard Shaw',
  },{
    quote: 'Becoming is better than being.',
    attr: 'Carol Dweck',
  },{
    quote: 'The problem human beings face is not that we aim too high and fail, but that we aim too low and succeed.',
    attr: 'Michelangelo',
  },{
    quote: 'You’re only one workout away from a good mood.',
  },{
    quote: 'The goal is the path and the path is the goal.',
    attr: 'Brad Stulberg',
  },{
    quote: 'Be the change that you wish to see in the world.',
    attr: 'Mahatma Gandhi',
  },{
    quote: 'It is never too late to be what you might have been.',
    attr: 'George Eliot',
  },{
    quote: 'Do what you can, with what you have, where you are.',
    attr: 'Theodore Roosevelt',
  },{
    quote: 'Success is not final, failure is not fatal: it is the courage to continue that counts.',
    attr: 'Winston S. Churchill',
  },{
    quote: 'You have to wait until tomorrow to find out what tomorrow will bring.',
    attr: 'Haruki Murakami',
  },{
    quote: 'And, when you want something, all the universe conspires in helping you to achieve it.',
    attr: 'Paulo Coelho',
  },{
    quote: 'If you can’t change it, change the way you think about it. Don’t complain.',
    attr: 'Maya Angelou',
  },{
    quote: 'Nothing is impossible, the word itself says “I’m possible”!',
    attr: 'Audrey Hepburn',
  },{
    quote: 'Happiness is not something ready made. It comes from your own actions.',
    attr: 'Dalai Lama XIV',
  },{
    quote: 'Most people run a race to see who is fastest. I run a race to see who has the most guts.',
    attr: 'Steve Prefontaine',
  },{
    quote: 'Whatever you are, be a good one.',
    attr: 'Abraham Lincoln',
  },{
    quote: 'May you live every day of your life.',
    attr: 'Jonathan Swift',
  },{
    quote: 'Don’t be pushed around by the fears in your mind. Be led by the dreams in your heart.',
    attr: 'Roy T. Bennett',
  },{
    quote: 'Always do what you are afraid to do.',
    attr: 'Ralph Waldo Emerson',
  },{
    quote: 'If you can’t fly then run, if you can’t run then walk, if you can’t walk then crawl, but whatever you do you have to keep moving forward.',
    attr: 'Martin Luther King Jr.',
  },{
    quote: 'It’s only after you’ve stepped outside your comfort zone that you begin to change, grow, and transform.',
    attr: 'Roy T. Bennett',
  },{
    quote: 'Don’t be afraid of your fears. They’re not there to scare you. They’re there to let you know that something is worth it.',
    attr: 'JoyBell C.',
  },{
    quote: 'You never fail until you stop trying.',
    attr: 'Albert Einstein',
  },{
    quote: 'You’re off to Great Places! Today is your day! Your mountain is waiting, So get on your way!',
    attr: 'Dr. Seuss',
  },{
    quote: 'You were born with wings, why prefer to crawl through life?',
    attr: 'Rumi',
  },{
    quote: 'Though nobody can go back and make a new beginning... Anyone can start over and make a new ending.',
    attr: 'Chico Xavier',
  },{
    quote: 'It does not matter how slowly you go as long as you do not stop.',
    attr: 'Confucius',
  },{
    quote: 'When one tugs at a single thing in nature, he finds it attached to the rest of the world.',
    attr: 'John Muir',
  },{
    quote: 'The key to success is consistency.',
    attr: 'Zak Frazer',
  },{
    quote: 'The question is not what you look at, but what you see.',
    attr: 'Henry David Thoreau',
  },{
    quote: 'Life has no remote....get up and change it yourself!',
    attr: 'Mark A. Cooper',
  },{
    quote: 'Two roads diverged in a wood, and I - I took the one less traveled by, And that has made all the difference.',
    attr: 'Robert Frost',
  },{
    quote: 'I must be willing to give up what I am in order to become what I will be.',
    attr: 'Albert Einstein',
  },{
    quote: 'Believe you can and you’re halfway there.',
    attr: 'Theodore Roosevelt',
  },{
    quote: 'Many of life’s failures are people who did not realize how close they were to success when they gave up.',
    attr: 'Thomas A. Edison',
  },{
    quote: 'We either make ourselves miserable, or we make ourselves strong. The amount of work is the same.',
    attr: 'Carlos Castaneda',
  },{
    quote: 'Consistency is what transforms average into excellence.',
    attr: 'Tony Robbins',
  },{
    quote: 'Success is the sum of small efforts, repeated day in and day out.',
    attr: 'Robert Collier',
  },{
    quote: 'For changes to be of any true value, they’ve got to be lasting and consistent.',
    attr: 'Tony Robbins',
  },{
    quote: 'If you’re going to achieve excellence in big things, you develop the habit in little matters.',
    attr: 'Colin Powell',
  },{
    quote: 'The measure of intelligence is the ability to change.',
    attr: 'Albert Einstein',
  },{
    quote: 'You are the one that possesses the keys to your being. You carry the passport to your own happiness.',
    attr: 'Diane von Furstenberg',
  },{
    quote: 'Make the most of yourself by fanning the tiny, inner sparks of possibility into flames of achievement.',
    attr: 'Golda Meir',
  },{
    quote: 'I didn’t get there by wishing for it or hoping for it, but by working for it.',
    attr: 'Estée Lauder',
  },{
    quote: 'The most difficult thing is the decision to act, the rest is merely tenacity.',
    attr: 'Amelia Earhart',
  },{
    quote: 'You can waste your lives drawing lines. Or you can live your life crossing them.',
    attr: 'Shonda Rhimes',
  },{
    quote: 'If you don’t like the road you’re walking, start paving another one.',
    attr: 'Dolly Parton',
  },{
    quote: 'Done is better than perfect.',
    attr: 'Sheryl Sandberg',
  },{
    quote: 'Step aggressively towards your fear – that is the step into bravery.',
    attr: 'Jocko Willink.',
  },{
    quote: 'Step out of the history that is holding you back. Step into the new story you are willing to create.',
    attr: 'Oprah Winfrey',
  },{
    quote: 'I choose to make the rest of my life the best of my life.',
    attr: 'Louise Hay',
  },{
    quote: 'To give anything less than your best, is to sacrifice the gift.',
    attr: 'Steve Prefontaine',
  },{
    quote: 'The question isn’t who is going to let me; it’s who is going to stop me.',
    attr: 'Ayn Rand',
  },{
    quote: 'A surplus of effort could overcome a deficit of confidence.',
    attr: 'Sonia Sotomayor',
  },{
    quote: 'Normal is not something to aspire to, it’s something to get away from.',
    attr: 'Jodie Foster',
  },{
    quote: 'Don’t look at your feet to see if you are doing it right. Just dance.',
    attr: 'Anne Lamott',
  },{
    quote: 'And the day came when the risk to remain tight in a bud was more painful than the risk it took to blossom.',
    attr: 'Anaïs Nin',
  },{
    quote: 'Never be limited by other people’s limited imaginations.',
    attr: 'Dr. Mae Jemison',
  },{
    quote: 'Whatever we believe about ourselves and our ability comes true for us.',
    attr: 'Susan L. Taylor',
  },{
    quote: 'Hold fast to dreams, for if dreams die, life is a broken winged bird that cannot fly.',
    attr: 'Langston Hughes',
  },{
    quote: 'If there is no struggle, there is no progress.',
    attr: 'Frederick Douglass',
  },{
    quote: 'Success is to be measured not so much by the position that one has reached in life as by the obstacles which he has overcome while trying to succeed.',
    attr: 'Booker T. Washington',
  },{
    quote: 'He who is not courageous enough to take risks will accomplish nothing in life.',
    attr: 'Muhammad Ali',
  },{
    quote: 'Learn as if you will live forever, live like you will die tomorrow.',
    attr: 'Mahatma Gandhi',
  },{
    quote: 'I never dreamed about success. I worked for it.',
    attr: 'Estée Lauder',
  },{
    quote: 'Either you run the day or the day runs you.',
    attr: 'Jim Rohn',
  },{
    quote: 'When we strive to become better than we are, everything around us becomes better too.',
    attr: 'Paulo Coelho',
  },{
    quote: 'Setting goals is the first step in turning the invisible into the visible.',
    attr: 'Tony Robbins',
  },{
    quote: 'The most difficult thing is the decision to act, the rest is merely tenacity.',
    attr: 'Amelia Earhart',
  },{
    quote: 'The elevator to success is out of order. You’ll have to use the stairs, one step at a time.',
    attr: 'Joe Girard',
  },{
    quote: 'I am not a product of my circumstances. I am a product of my decisions.',
    attr: 'Stephen R. Covey',
  },{
    quote: 'One of the differences between some successful and unsuccessful people is that one group is full of doers, while the other is full of wishers.',
    attr: 'Edmond Mbiaka',
  },{
    quote: 'When you arise in the morning think of what a privilege it is to be alive, to think, to enjoy, to love…',
    attr: 'Marcus Aurelius',
  },{
    quote: 'Inspiration does exist, but it must find you working.',
    attr: 'Pablo Picasso',
  },{
    quote: 'Don’t settle for average. Bring your best to the moment. Then, whether it fails or succeeds, at least you know you gave all you had.',
    attr: 'Angela Bassett',
  },{
    quote: 'The only one who can tell you “you can’t win” is you and you don’t have to listen.',
    attr: 'Jessica Ennis',
  },{
    quote: 'Take your victories, whatever they may be, cherish them, use them, but don’t settle for them.',
    attr: 'Mia Hamm',
  },{
    quote: 'If you really want to do something, you’ll find a way. If you don’t, you’ll find an excuse.',
    attr: 'Jim Rohn',
  },{
    quote: 'The greater the difficulty, the more the glory in surmounting it.',
    attr: 'Epicurus',
  },{
    quote: 'Courage doesn’t always roar. Sometimes courage is a quiet voice at the end of the day saying, ‘I will try again tomorrow.’',
    attr: 'Mary Anne Radmacher',
  },{
    quote: 'A year from now you will wish you had started today.',
    attr: 'Unknown',
  },{
    quote: 'When everything seems to be going against you, remember that the airplane takes off against the wind, not with it.',
    attr: 'Henry Ford',
  },{
    quote: 'You cannot always control what goes on outside. But you can always control what goes on inside.',
    attr: 'Wayne Dyer',
  },{
    quote: 'Make sure your worst enemy doesn’t live between your own two ears.',
    attr: 'Laird Hamilton',
  },{
    quote: 'Life is 10% what happens to you and 90% how you respond.',
    attr: 'Charles R. Swindoll.',
  },{
    quote: 'The best way out is always through.',
    attr: 'Robert Frost',
  },{
    quote: 'Courage is like a muscle. We strengthen it by use.',
    attr: 'Ruth Gordo',
  },{
    quote: 'If you don’t like the road you’re walking, start paving another one.',
    attr: 'Dolly Parton',
  },{
    quote: 'I choose to make the rest of my life the best of my life.',
    attr: 'Louise Hay',
  },{
    quote: 'Some people want it to happen, some wish it would happen, others make it happen.',
    attr: 'Michael Jordan',
  },{
    quote: 'Perfection is not attainable. But if we chase perfection we can catch excellence.',
    attr: 'Vince Lombardi',
  },{
    quote: 'Optimism is the faith that leads to achievement. Nothing can be done without hope and confidence.',
    attr: 'Helen Keller',
  },{
    quote: 'Do one thing every day that scares you.',
    attr: 'Eleanor Roosevelt',
  },{
    quote: 'Happiness is not something ready made. It comes from your own actions.',
    attr: 'Dalai Lama XIV',
  },{
    quote: 'People need to change their perspectives, not their problems.',
    attr: 'John Maxwell',
  },{
    quote: 'How wonderful it is that nobody need wait a single moment before starting to improve the world.',
    attr: 'Anne Frank',
  },{
    quote: 'Great things are done by a series of small things brought together',
    attr: 'Vincent Van Gogh',
  },{
    quote: 'That which does not kill us makes us stronger.',
    attr: 'Friedrich Nietzsche',
  },{
    quote: 'The hard days are what make you stronger.',
    attr: 'Aly Raisman',
  },{
    quote: 'Successful people make their decisions based on where they want to be.',
    attr: 'Benjamin Hardy',
  },{
    quote: 'Work hard for what you want because it won’t come to you without a fight.',
    attr: 'Leah LaBelle',
  },{
    quote: 'Work hard, be kind, and amazing things will happen.',
    attr: 'Conan O’Brien',
  },{
    quote: 'If you work on something a little bit every day, you end up with something that is massive.',
    attr: 'Kenneth Goldsmith',
  },{
    quote: 'The big secret in life is that there is no secret. Whatever your goal, you can get there if you’re willing to work.',
    attr: 'Oprah Winfrey',
  },{
    quote: 'At any given moment you have the power to say: this is not how the story is going to end.',
    attr: 'Unknown',
  },{
    quote: 'Amateurs sit around and wait for inspiration. The rest of us just get up and go to work.',
    attr: 'Stephen King',
  },{
    quote: 'Sometimes when you’re in a dark place you think you’ve been buried but you’ve actually been planted.',
    attr: 'Christine Caine',
  },{
    quote: 'Don’t limit your challenges. Challenge your limits.',
    attr: 'Unknown',
  },{
    quote: 'Whenever you find yourself doubting how far you can go, just remember how far you have come.',
    attr: 'Unknown',
  },{
    quote: 'Success is the progressive realization of a predetermined goal.',
    attr: 'John Maxwell ',
  },{
    quote: 'He who has a why to live for can bear almost any how.',
    attr: 'Friedrich Nietzsche',
  },{
    quote: 'Go the extra mile. It’s never crowded there.',
    attr: 'Dr. Wayne D. Dyer',
  },{
    quote: 'We can do anything we want to if we stick to it long enough.',
    attr: 'Helen Keller',
  },{
    quote: 'Success is no accident. It is hard work, perseverance, learning, studying, sacrifice and most of all, love of what you are doing or learning to do.',
    attr: 'Pele',
  },
];
